import Box, { BoxProps } from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import OpenIcon from '@material-ui/icons/OpenInNewOutlined';
import dayjs from 'dayjs';
import React from 'react';

import { AssetData } from '../useWWLAsset';
import Item from './Item';
import Spacer from './Spacer';
import appIcon from './wwl_app_icon.png';
import YearSubtitle from './YearSubtitle';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: '0.1em',
    textTransform: 'uppercase'
  },
  appIcon: {
    marginLeft: theme.spacing(2),
    borderRadius: 8
  },
  link: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'top',
    flexWrap: 'nowrap',
    '& > *': {
      marginLeft: theme.spacing(1),
      '&:first-child': {
        marginLeft: 0
      }
    }
  }
}));

type SupportedBoxProps = Pick<BoxProps, 'flex' | 'height' | 'width'>;

export interface WorkHistoryProps extends SupportedBoxProps {
  asset: AssetData;
}

const WorkHistory: React.FC<WorkHistoryProps> = ({ asset, ...rootProps }) => {
  const classes = useStyles();

  return (
    <Box overflow="hidden auto" {...rootProps}>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Box flex={1}>
          <Typography variant="h5" className={classes.title}>
            Work History
          </Typography>
          <Typography color="secondary">Provided by Work with LUKE™</Typography>
        </Box>
        <img
          src={appIcon}
          alt="Work with LUKE"
          height={58}
          className={classes.appIcon}
        />
      </Box>
      <Box mt={4}>
        {(asset?.timeline || []).map((item, index) => {
          const isFirst = index === 0;

          let lines;
          if (isFirst) lines = 'END_ONLY' as const;
          if (index === asset.timeline.length - 1) {
            lines = 'NONE' as const;
          }

          const currentYear = dayjs().year().toString();
          let showYear = isFirst && item.year !== currentYear;

          if (!isFirst && item.year !== asset.timeline[index - 1].year) {
            showYear = true;
          }

          const showSpacer = !showYear && !isFirst;

          return (
            <React.Fragment key={item.id}>
              {showYear && (
                <YearSubtitle
                  year={item.year}
                  hideLine={lines === 'END_ONLY'}
                />
              )}
              {showSpacer && <Spacer />}
              <Item workorder={item} lines={lines} />
            </React.Fragment>
          );
        })}
      </Box>
      <Box mt={3}>
        <Link
          href={`https://workorders.lukeapps.io/assets/${asset.id}`} // TODO. env
          color="primary"
          className={classes.link}
        >
          <OpenIcon />
          <Typography component="span">
            Asset insights report in Work with LUKE
          </Typography>
        </Link>
      </Box>
    </Box>
  );
};

export default WorkHistory;
