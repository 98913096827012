import { MapProvider, MapboxProvider } from '@ljagis/react-mapping';
import { Box, LinearProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import AlertMessage from '../components/AlertMessage';
import Layout from '../components/Layout';
import { DistrictConfig } from '../models/DistrictConfig';

const {
  REACT_APP_MAPBOX_ACCESS_TOKEN: mapboxAccessToken = '',
  REACT_APP_CONFIGS_BASE_URL: configsBaseUrl = '',
  REACT_APP_CONFIG_PATH: configPath = ''
} = process.env;

interface DistrictMapProps {
  district: string;
}

const DistrictMap: React.FC<DistrictMapProps> = ({ district }) => {
  const [error, setError] = useState<null | string>(null);
  const [config, setConfig] = useState<DistrictConfig | null>(null);

  useEffect(() => {
    const getDistrictInfo = async () => {
      try {
        const configResponse = await fetch(
          `${configsBaseUrl}/${district}/${configPath}`
        );
        const config = await configResponse.json();
        setConfig(config);
      } catch (err) {
        if (err instanceof SyntaxError) {
          setError(`${district} does not exist`);
          return;
        }
        setError('Error fetching district info');
      }
    };
    getDistrictInfo();
  }, [district]);

  if (error) return <AlertMessage message={error} severity="error" />;

  if (!config)
    return (
      <Box p={3}>
        <LinearProgress />
      </Box>
    );

  return (
    <MapboxProvider accessToken={mapboxAccessToken}>
      <MapProvider>
        <Layout {...config} />
      </MapProvider>
    </MapboxProvider>
  );
};

export default DistrictMap;
