import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

import { useLineStyles } from './useLineStyles';

const useStyles = makeStyles((theme) => ({
  root: { height: theme.spacing(2) }
}));

const Spacer: React.FC = () => {
  const classes = useStyles();
  const lineClasses = useLineStyles();

  return (
    <Box px={1.5} className={classes.root}>
      <Box height={1} className={lineClasses.line} />
    </Box>
  );
};

export default Spacer;
