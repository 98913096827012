import { Auth0Provider } from '@auth0/auth0-react';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import * as Sentry from '@sentry/browser';
import { inject as vercelInject } from '@vercel/analytics';
import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import { ErrorBoundary } from './components/ErrorBoundary';
import theme from './theme';

const {
  REACT_APP_AUTH0_CLIENT_ID: auth0ClientId = '',
  REACT_APP_AUTH0_DOMAIN: auth0Domain = '',
  REACT_APP_SENTRY_DSN: sentryDSN = ''
} = process.env;

Sentry.init({
  dsn: sentryDSN,
  blacklistUrls: [/https?:\/\/localhost/],
  environment: process.env.NODE_ENV,
  release: `${process.env.npm_package_name}@${process.env.npm_package_version}`
});

vercelInject();

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <Auth0Provider
        domain={auth0Domain}
        clientId={auth0ClientId}
        redirectUri={`${window.location.origin}/authcallback`}
      >
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <App />
        </ThemeProvider>
      </Auth0Provider>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);
