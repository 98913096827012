import { useGeoJSONLayer, useMapNavigation } from '@ljagis/react-mapping';
import { BoxProps, List, ListItem, ListItemText } from '@material-ui/core';
import bbox from '@turf/bbox';
import React, { useState } from 'react';

import { EngineeringReportProperties } from '../models/DistrictConfig';

type ReportFeature = GeoJSON.Feature<
  GeoJSON.Polygon | GeoJSON.MultiPolygon,
  EngineeringReportProperties
>;

const layers = [
  {
    type: 'fill' as const,
    filter: [
      'any',
      ['match', ['geometry-type'], 'Polygon', true, false],
      ['match', ['geometry-type'], 'MultiPolygon', true, false]
    ],
    paint: {
      'fill-color': 'rgba(24, 255, 255, 0.4)'
    }
  }
];

const ReportsList: React.FC<
  {
    items: ReportFeature[];
  } & Pick<BoxProps, 'flex'>
> = ({ items }) => {
  const { setData } = useGeoJSONLayer({ layers });
  const { fitBounds } = useMapNavigation();
  const [selectedIndex, setSelectedIndex] =
    useState<number | undefined>(undefined);

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (!selectedIndex && selectedIndex !== 0) {
      return;
    }
    // arrow up/down button should select next/previous list element
    if (e.key === 'ArrowUp' && selectedIndex > 0) {
      setSelectedIndex(selectedIndex - 1);
      setData(items[selectedIndex - 1]);
      try {
        fitBounds(
          bbox(items[selectedIndex - 1].geometry) as [
            number,
            number,
            number,
            number
          ],
          {
            maxZoom: 17,
            padding: 80
          }
        );
      } catch (_err) {
        // NOOP
      }
    } else if (e.key === 'ArrowDown' && selectedIndex < items.length - 1) {
      setSelectedIndex(selectedIndex + 1);
      setData(items[selectedIndex + 1]);
      try {
        fitBounds(
          bbox(items[selectedIndex + 1].geometry) as [
            number,
            number,
            number,
            number
          ],
          {
            maxZoom: 17,
            padding: 80
          }
        );
      } catch (_err) {
        // NOOP
      }
    }
  };

  return (
    <List>
      {items.map((item, index: number) => (
        <ListItem
          button
          dense
          divider
          selected={selectedIndex === index}
          key={item.id || index}
          onKeyDown={(e) => handleKeyDown(e)}
          onClick={() => {
            setData(item);
            setSelectedIndex(index);
            try {
              fitBounds(
                bbox(item.geometry) as [number, number, number, number],
                {
                  maxZoom: 17,
                  padding: 80
                }
              );
            } catch (_err) {
              // NOOP
            }
          }}
        >
          <ListItemText
            primary={item.properties.Item}
            secondary={item.properties.Category}
          />
        </ListItem>
      ))}
    </List>
  );
};

export default ReportsList;
