import dayjs from 'dayjs';
import { useCallback, useState } from 'react';

export interface AssetData {
  id: string;
  /** Timeline always sorted in descending chronological order */
  timeline: {
    id: string;
    date: string;
    year: string;
    workorderNumber: string;
    title: string;
    status: string;
  }[];
}

export interface WWLAssetHookResult {
  data: AssetData | null;
  setAssetId: (assetId: string) => void;
  clearData: () => void;
}

export function useWWLAsset(): WWLAssetHookResult {
  const [data, setData] = useState<WWLAssetHookResult['data']>(null);

  const setAssetId = useCallback(async (assetId: string) => {
    const endpoint = `https://qpyvpav6tj.execute-api.us-east-1.amazonaws.com/prod/assets/${assetId}`; // TODO. env

    try {
      const wwlAPIResponse = await fetch(endpoint);

      if (wwlAPIResponse.status !== 200) {
        throw new Error(`HTTP ${wwlAPIResponse.status}`);
      }

      const assetData = await wwlAPIResponse.json();

      setData({
        ...assetData,
        timeline: (assetData.timeline || []).map(
          (item: AssetData['timeline'][0]) => {
            const date = dayjs(item.date);
            return {
              ...item,
              date: date.format('MMM D'),
              year: date.year().toString()
            };
          }
        )
      });
    } catch (err) {
      setData(null);
    }
  }, []);

  const clearData = useCallback(() => {
    setData(null);
  }, []);

  return { data, setAssetId, clearData };
}
