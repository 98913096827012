import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import { AssetData } from '../useWWLAsset';
import { useLineStyles } from './useLineStyles';

const useStyles = makeStyles({
  leftSection: {
    backgroundColor: '#E8EAEE',
    borderRadius: '8px 0 0 8px'
  },
  rightSection: {
    backgroundColor: '#EFF1F4',
    borderRadius: '0 8px 8px 0'
  },
  title: {
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': 2,
    overflow: 'hidden'
  }
});

export interface ItemProps {
  workorder: AssetData['timeline'][0];
  lines?: 'ALL' | 'START_ONLY' | 'END_ONLY' | 'NONE';
}

const statusColors: Record<string, string> = {
  // @ljagis/luke/apps/workorders/src/hooks/useStatus.ts
  SUBMITTED: '#01BAEF', // Blue
  DENIED: '#CD533B', // Red
  CANCELLED: '#DF78DB', // Red
  COMPLETED: '#70B77E', // Green
  STARTED: '#FFBA08', // Orange
  REOPENED: '#01BAEF', // Blue
  ONHOLD: '#CECE53' // Orange
};

const Item: React.FC<ItemProps> = ({ workorder, lines = 'ALL' }) => {
  const classes = useStyles();
  const lineClasses = useLineStyles();

  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="nowrap"
      alignItems="stretch"
    >
      <Box
        width={108}
        display="flex"
        flexDirection="row"
        alignItems="center"
        px={1.5}
        className={classes.leftSection}
      >
        <Box alignSelf="stretch" display="flex" flexDirection="column" mr={1.5}>
          <Box
            flex={1}
            className={
              ['END_ONLY', 'NONE'].includes(lines)
                ? undefined
                : lineClasses.line
            }
          />
          <Box className={lineClasses.point} />
          <Box
            flex={1}
            className={
              ['START_ONLY', 'NONE'].includes(lines)
                ? undefined
                : lineClasses.line
            }
          />
        </Box>
        <Box>
          <Typography gutterBottom>{workorder.date}</Typography>
          <Typography color="textSecondary">
            {workorder.workorderNumber}
          </Typography>
        </Box>
      </Box>
      <Box flex={1} p={1.5} className={classes.rightSection}>
        <Typography gutterBottom className={classes.title}>
          {workorder.title}
        </Typography>
        <Typography style={{ color: statusColors[workorder.status] }}>
          {workorder.status}
        </Typography>
      </Box>
    </Box>
  );
};

export default Item;
