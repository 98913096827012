import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';

/**
 * Higher order component to require authenticating prior to rendering
 * @example
 * ```typescript
 * import { Auth0Provider } from '@auth0/auth0-react';
 * import { withAuth0Authenticated } from 'components';
 * import App from './components/App';
 * ReactDOM.render(
 *   <Auth0Provider
 *     domain="lja.auth0.com"
 *     clientId="CLIENT_ID"
 *     redirectUri={window.location.href.replace(window.location.hash, '')}
 *   >
 *     <AppWithAuth />
 *   </Auth0Provider>,
 *   document.getElementById('root')
 * );
 * ```
 */

/* eslint-disable  @typescript-eslint/ban-types */
export const withAuth0Authenticated = <P extends object>(
  WrappedComponent: React.ComponentType<P>,
  district: string
) => {
  // eslint-disable-next-line
  return (props: P) => {
    const { isLoading, loginWithRedirect, isAuthenticated, user } = useAuth0();

    // mcmud128a has a unique connection that uses spaces
    const districtConnection =
      district === 'mcmud128a' ? 'mc-mud-128a' : district;

    // Each district (kcfwsdno4a, mcmud113121, etc...) has a specific auth0 database/connection.
    // A single Auth0 application for https://auth.lja.com allows all client connections.
    // User may be authenticated with a connection not for the current district
    // if they sign in, then change applications (navigate to different district subdomain).
    // `isAuthenticated` is not enough to load app. User should be authenticated with the correct connection.
    // `https://auth.lja.com/connection` is a custom claim added via an Auth0 rule.
    // gis-admins allows all districts
    const isAuthenticatedWithClient =
      isAuthenticated &&
      user &&
      (user['https://auth.lja.com/connection'] === 'gis-admins' ||
        user['https://auth.lja.com/connection'] === districtConnection);

    React.useEffect(() => {
      if (isLoading || isAuthenticatedWithClient) {
        return;
      }
      const fn = async () => {
        // TODO Handle invalid connection. No Auth0 connection for districtConnection.
        // If no connection, URL params includes `error=invalid_request&error_description=the%20connection%20is%20not%20enabled`
        await loginWithRedirect({
          connection: districtConnection,
          // We're redirecting all users to `https://districts.lja.com/authcallback` so that we don't
          // have to create an "Allowed Callback URL" for each new district.
          // appState is passing district through state so it can be used to redirect once login resolved.
          appState: { target: district }
        });
      };
      fn();
    }, [
      isLoading,
      isAuthenticatedWithClient,
      loginWithRedirect,
      districtConnection
    ]);

    return isAuthenticatedWithClient ? <WrappedComponent {...props} /> : null;
  };
};
