import React from 'react';
import { useParams } from 'react-router-dom';

import DistrictMap from '../pages/DistrictMap';
import { withAuth0Authenticated } from './Auth0Authenticated';

export interface AuthGatewayProps {
  /** These districts will not require users to authenticate */
  accessPublic?: string[];
}

const AuthGateway: React.FC<AuthGatewayProps> = ({ accessPublic = [] }) => {
  const { district } = useParams<{ district: string }>();

  if (accessPublic.includes(district.toLowerCase())) {
    return <DistrictMap district={district} />;
  }

  const DistrictMapWithAuth = withAuth0Authenticated(DistrictMap, district);
  return <DistrictMapWithAuth district={district} />;
};

export default AuthGateway;
