import { useAuth0 } from '@auth0/auth0-react';
import { Box, LinearProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import AlertMessage from './AlertMessage';

const AuthCallback: React.FC = () => {
  const { handleRedirectCallback } = useAuth0();
  const history = useHistory();
  const [error, setError] = useState<null | string>(null);

  useEffect(() => {
    const tryRedirect = async () => {
      try {
        const redirectLoginResult: { appState?: { target: string } } =
          await handleRedirectCallback();
        if (!redirectLoginResult.appState) return;

        history.push(redirectLoginResult.appState.target);
      } catch (err) {
        setError(err.message);
      }
    };
    tryRedirect();
  }, [handleRedirectCallback, history]);

  if (error) {
    return <AlertMessage message={error} severity="error" />;
  }
  return (
    <Box p={3}>
      <LinearProgress />
    </Box>
  );
};

export default AuthCallback;
