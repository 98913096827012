import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React from 'react';

import { useLineStyles } from './useLineStyles';

const useStyles = makeStyles((theme) => ({
  label: {
    color: theme.palette.primary.dark
  }
}));

export interface YearSubtitleProps {
  year: string;
  hideLine?: boolean;
}

const YearSubtitle: React.FC<YearSubtitleProps> = ({ year, hideLine }) => {
  const classes = useStyles();
  const lineClasses = useLineStyles();

  return (
    <Box display="flex" flexDirection="row" alignItems="center" px={1.5}>
      <Box alignSelf="stretch" display="flex" flexDirection="column" mr={1.5}>
        <Box flex={1} className={hideLine ? undefined : lineClasses.line} />
      </Box>
      <Box pt={hideLine ? 0 : 3} pb={3}>
        <Typography variant="h5" className={classes.label}>
          {year}
        </Typography>
      </Box>
    </Box>
  );
};

export default YearSubtitle;
