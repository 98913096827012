import { fade, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  point: {
    height: 7,
    width: 7,
    borderRadius: 7,
    background: theme.palette.primary.main
  },
  line: {
    marginLeft: 3,
    width: 1,
    background: fade(theme.palette.primary.main, 0.5)
  }
}));

export function useLineStyles(): ReturnType<typeof useStyles> {
  return useStyles();
}
