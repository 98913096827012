import { useGeoJSONLayer, useMapNavigation } from '@ljagis/react-mapping';
import { AnyAttribute, FeatureDetails } from '@ljagis/react-mapping-components';
import {
  Box,
  BoxProps,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Toolbar
} from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBackIosOutlined';
import bbox from '@turf/bbox';
import dayjs from 'dayjs';
import isString from 'lodash.isstring';
import React, { useState } from 'react';
import isURL from 'validator/lib/isURL';

import { SectionProperties } from '../models/DistrictConfig';

type SectionFeature = GeoJSON.Feature<GeoJSON.Geometry, SectionProperties>;

const layers = [
  {
    type: 'fill' as const,
    filter: [
      'any',
      ['match', ['geometry-type'], 'Polygon', true, false],
      ['match', ['geometry-type'], 'MultiPolygon', true, false]
    ],
    paint: {
      'fill-color': 'rgba(24, 255, 255, 0.4)'
    }
  }
];

const getAttributes = (feature: SectionFeature): AnyAttribute[] => {
  const featProps = feature.properties;

  if (!featProps) {
    return [];
  }

  // Keys for feature properties to render in component.
  // Properties with keys starting with "_" will be skipped.
  // Properties with falsy values will be skipped. Mostly to not show empty strings.
  const keysToDisplay = (
    Object.keys(featProps) as (keyof SectionProperties)[]
  ).filter((key) => key[0] !== '_' && featProps[key] && key !== 'Section');

  const isoDateTest = new RegExp(
    /(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/
  );

  return keysToDisplay.map((key) => {
    if (
      isString(featProps[key]) &&
      isURL(featProps[key], { protocols: ['http', 'https'] })
    ) {
      return {
        kind: 'link',
        label: key,
        url: featProps[key]
      };
    }

    if (isString(featProps[key]) && isoDateTest.test(featProps[key])) {
      return {
        kind: 'text',
        label: key,
        value: dayjs(featProps[key]).format('MM-DD-YYYY')
      };
    }

    return {
      kind: 'text',
      label: key,
      value: featProps[key]
    };
  });
};

const SectionsList: React.FC<
  {
    items: SectionFeature[];
  } & Pick<BoxProps, 'flex'>
> = ({ items, ...rootProps }) => {
  const { setData, clearData } = useGeoJSONLayer({ layers });
  const { fitBounds } = useMapNavigation();

  const [single, setSingle] = useState<SectionFeature | null>(null);

  if (single) {
    const attributes = getAttributes(single);

    return (
      <Box
        display="flex"
        flexDirection="column"
        overflow="hidden"
        {...rootProps}
      >
        <Box p={2} pb={2} flex="0 0 auto">
          <Toolbar disableGutters style={{ minHeight: 0 }}>
            <Button
              color="primary"
              startIcon={<BackIcon />}
              style={{ marginLeft: -8, marginTop: -6, marginBottom: -6 }}
              onClick={() => {
                clearData();
                setSingle(null);
              }}
            >
              Back
            </Button>
            <Box flex={1} />
          </Toolbar>
        </Box>
        <Divider />
        <FeatureDetails
          flex={1}
          p={2}
          pt={2}
          title={`${single.properties.Section}`}
          attributes={attributes as AnyAttribute[]}
        />
      </Box>
    );
  }

  return (
    <List>
      {items.map((item, index: number) => (
        <ListItem
          button
          dense
          divider
          key={item.id || index}
          onClick={() => {
            setData(item);
            setSingle(item);
            try {
              fitBounds(
                bbox(item.geometry) as [number, number, number, number],
                {
                  maxZoom: 19,
                  padding: 80
                }
              );
            } catch (_err) {
              // NOOP
            }
          }}
        >
          <ListItemText
            primary={item.properties.Section}
            secondary={
              item.properties['Plans Date'] &&
              dayjs(item.properties['Plans Date']).format('MMMM YYYY')
            }
          />
        </ListItem>
      ))}
    </List>
  );
};

export default SectionsList;
