import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import AlertMessage from './components/AlertMessage';
import AuthCallback from './components/AuthCallback';
import AuthGateway from './components/AuthGateway';

/** Districts at these paths will not require users to authenticate */
const publicSites = ['awwa', 'cityofsimonton', 'tag', 'dcol'];

const App: React.FC = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/authcallback">
          <AuthCallback />
        </Route>
        <Route exact path="/:district">
          <AuthGateway accessPublic={publicSites} />
        </Route>
        <Route path="*">
          <AlertMessage message="Path does not exist" severity="error" />
        </Route>
      </Switch>
    </BrowserRouter>
  );
};

export default App;
