import { SvgIcon } from '@material-ui/core';
import React from 'react';

import { ReactComponent as ReportIcon } from './engineeringReportIcon.svg';

const EngineeringReportIcon: React.FC = () => {
  return <SvgIcon component={ReportIcon} viewBox="0 0 48 48" />;
};

export default EngineeringReportIcon;
