import {
  Header,
  Layout1,
  Sidebar,
  StackedTitle,
  useFuzzySearch
} from '@ljagis/react-core';
import {
  Map,
  mapboxGeocode,
  useMapIdentify,
  useMapViewport,
  useMeasure
} from '@ljagis/react-mapping';
import {
  GeolocationButton,
  Identify,
  LayerVisibility,
  Layers,
  LocationSearch,
  LocationSearchResult,
  MapOverlay,
  MeasureResults,
  StyleGallery,
  ZoomInButton,
  ZoomOutButton
} from '@ljagis/react-mapping-components';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Theme,
  Toolbar,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import IdentifyIcon from '@material-ui/icons/FeaturedPlayListOutlined';
import LayersIcon from '@material-ui/icons/LayersOutlined';
import ListIcon from '@material-ui/icons/ListAlt';
import MeasureIcon from '@material-ui/icons/StraightenOutlined';
import React, { useCallback, useEffect, useState } from 'react';

import { AssetData, useWWLAsset } from '../LUKE/useWWLAsset';
import WWLWorkHistory from '../LUKE/WorkHistory';
import {
  DistrictConfig,
  EngineeringReportProperties
} from '../models/DistrictConfig';
import EngineeringReportIcon from './EngineeringReportIcon';
import ReportsList from './ReportsList';
import SectionsList from './SectionsList';

const { REACT_APP_MAPBOX_ACCESS_TOKEN: mapboxAccessToken = '' } = process.env;

type sidebars = 'layers' | 'identify' | 'sections' | 'measure' | 'reports';

/** TS translation - parameters from `onSelection` prop (a function) of Identify component */
type IdentifySelectionParams = Parameters<
  Required<Parameters<typeof Identify>[0]>['onSelection']
>;

export const searchLocation = (
  mapboxAccessToken: string,
  proximity: [number, number] | undefined
) => {
  return (query: string): Promise<LocationSearchResult[]> =>
    mapboxGeocode(query, mapboxAccessToken, {
      proximity
    });
};

type LayoutProps = DistrictConfig;

const useStyles = makeStyles((theme: Theme) => ({
  disabledHeaderButton: {
    opacity: 0.6,
    color: `${theme.palette.primary.main} !important`
  }
}));

const Layout: React.FC<LayoutProps> = ({
  logo,
  title,
  subtitle = 'District Map',
  mapStyles,
  sectionsTitle = 'Sections',
  sections,
  engineeringReport,
  showLegend = false
}) => {
  const classes = useStyles();

  const {
    layerNames: identifyLayers,
    features: identifyFeatures,
    location: identifyLocation,
    clearFeatures: clearIdentify,
    activate: activateIdentify,
    deactivate: deactivateIdentify
  } = useMapIdentify();

  const {
    length: measureLength,
    area: measureArea,
    lastPoint: measureLastPoint,
    isFinished: isMeasureFinished,
    activate: activateMeasure,
    deactivate: deactivateMeasure,
    reset: resetMeasure
  } = useMeasure({
    isActive: false
  });

  const { center } = useMapViewport();

  const {
    data: selectionWorkHistory,
    setAssetId: fetchAssetHistory,
    clearData: clearAssetHistory
  } = useWWLAsset();

  const [sidebar, setSidebar] = useState<sidebars | undefined>(
    sections ? 'sections' : 'layers'
  );

  const [engineeringReportFeatures, setEngineeringReportFeatures] = useState<
    GeoJSON.Feature<
      GeoJSON.Polygon | GeoJSON.MultiPolygon,
      EngineeringReportProperties
    >[]
  >([]);

  const { results: sectionList, setQuery: searchSections } = useFuzzySearch({
    list: sections?.features || [],
    options: { keys: ['properties.Section'], threshold: 0.2 }
  });

  const lukeIntegrationOnIdentify = useCallback(
    (feature: IdentifySelectionParams[0]) => {
      if (!feature) {
        clearAssetHistory();
        return;
      }

      // TODO. Better way to access asset ID attribute.
      // Currently depends on map style identify config.
      const assetId = feature?.properties.attributes.find(
        (a) => a.label === 'Asset ID'
      )?.value as string;

      if (!assetId) {
        clearAssetHistory();
        return;
      }

      fetchAssetHistory(assetId);
    },
    [clearAssetHistory, fetchAssetHistory]
  );

  const sidebars: Record<
    sidebars,
    { title: string; content: React.ReactNode; enableSearch?: boolean }
  > = {
    sections: {
      title: sectionsTitle,
      enableSearch: true,
      content: <SectionsList flex={1} items={sectionList} />
    },
    layers: {
      title: 'Layers',
      content: (
        <Box flex={1} display="flex" flexDirection="column" minHeight={0}>
          {showLegend ? <Layers flex={1} /> : <LayerVisibility flex={1} />}
          <Divider />
          <StyleGallery items={mapStyles} />
        </Box>
      )
    },
    identify: {
      title: 'Feature Details',
      content: (
        <Box flex={1}>
          <Identify
            layerNames={identifyLayers}
            features={identifyFeatures}
            location={identifyLocation}
            onClearFeatures={clearIdentify}
            onSelection={lukeIntegrationOnIdentify}
          />
          {Boolean(selectionWorkHistory) && (
            <React.Fragment>
              <Box mt={2} mb={1}>
                <Divider />
              </Box>
              <Box py={3} px={2}>
                <WWLWorkHistory asset={selectionWorkHistory as AssetData} />
              </Box>
            </React.Fragment>
          )}
        </Box>
      )
    },
    measure: {
      title: 'Measure',
      content: (
        <Box flex={1} display="flex" flexDirection="column" overflow="hidden">
          {measureLastPoint !== null && (
            <React.Fragment>
              <Box py={2}>
                <Toolbar disableGutters style={{ minHeight: 0 }}>
                  <Box mx={2}>
                    {!isMeasureFinished && (
                      <Typography variant="body2" color="textSecondary">
                        Double click to complete
                      </Typography>
                    )}
                  </Box>
                  <Box flex={1} />
                  <Box mr={1}>
                    <Button
                      color="primary"
                      style={{
                        marginTop: -6,
                        marginBottom: -6
                      }}
                      onClick={resetMeasure}
                    >
                      Start Over
                    </Button>
                  </Box>
                </Toolbar>
              </Box>
              <Divider />
            </React.Fragment>
          )}
          <Box p={2} flex={1} overflow="hidden auto">
            <MeasureResults
              area={measureArea}
              length={measureLength}
              lastPoint={measureLastPoint}
            />
          </Box>
        </Box>
      )
    },
    reports: {
      title: 'Engineering Report',
      content: <ReportsList flex={1} items={engineeringReportFeatures} />
    }
  };

  useEffect(() => {
    if (engineeringReport) {
      fetch(engineeringReport)
        .then((response) => response.json())
        .then((data) => setEngineeringReportFeatures(data.features));
    }
  }, [engineeringReport]);

  // Open identify sidebar on any new identified features
  useEffect(() => {
    if (!identifyFeatures.length) {
      return;
    }
    setSidebar('identify');
  }, [identifyFeatures]);

  // Activate/deactivate identify vs measure when measure sidebar opens
  useEffect(() => {
    if (sidebar !== 'measure') return undefined;
    deactivateIdentify();
    activateMeasure();

    return () => {
      activateIdentify();
      deactivateMeasure();
    };
  }, [
    sidebar,
    activateIdentify,
    deactivateIdentify,
    activateMeasure,
    deactivateMeasure
  ]);

  // Reset section search as 'sections' sidebar is exited
  useEffect(() => {
    if (sidebar !== 'sections') return undefined;
    return () => {
      searchSections('');
    };
  }, [sidebar, searchSections]);

  return (
    <Layout1
      height="100vh"
      header={
        <Header minHeight={56} color="secondary">
          {logo !== undefined && (
            <Box
              mr={2}
              height={1}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <img src={logo} alt="Logo" style={{ maxHeight: '48px' }} />
            </Box>
          )}
          <Box flex="1 1 auto">
            {title !== undefined && (
              <StackedTitle title={title} subtitle={subtitle} />
            )}
          </Box>
          {sections !== undefined && (
            <Tooltip title="Sections">
              <span>
                <IconButton
                  color="inherit"
                  disabled={sidebar === 'sections'}
                  classes={{
                    disabled: classes.disabledHeaderButton
                  }}
                  onClick={() => setSidebar('sections')}
                >
                  <ListIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
          <Tooltip title="Layers">
            <span>
              <IconButton
                color="inherit"
                disabled={sidebar === 'layers'}
                classes={{
                  disabled: classes.disabledHeaderButton
                }}
                onClick={() => setSidebar('layers')}
              >
                <LayersIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Measure">
            <span>
              <IconButton
                color="inherit"
                disabled={sidebar === 'measure'}
                classes={{
                  disabled: classes.disabledHeaderButton
                }}
                onClick={() => setSidebar('measure')}
              >
                <MeasureIcon />
              </IconButton>
            </span>
          </Tooltip>
          <Tooltip title="Feature Details">
            <span>
              <IconButton
                color="inherit"
                disabled={sidebar === 'identify'}
                edge={!engineeringReportFeatures.length ? 'end' : false}
                classes={{
                  disabled: classes.disabledHeaderButton
                }}
                onClick={() => setSidebar('identify')}
              >
                <IdentifyIcon />
              </IconButton>
            </span>
          </Tooltip>
          {Boolean(engineeringReportFeatures.length) && (
            <Tooltip title="Engineering Report">
              <span>
                <IconButton
                  color="inherit"
                  disabled={sidebar === 'reports'}
                  edge="end"
                  classes={{
                    disabled: classes.disabledHeaderButton
                  }}
                  onClick={() => setSidebar('reports')}
                >
                  <EngineeringReportIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
        </Header>
      }
      map={
        <Map
          style={{ height: '100%' }}
          initialMapStyle={mapStyles[0]?.styleUrl}
        />
      }
      mapOverlayTopLeft={[
        <MapOverlay key="locationSearch">
          <Box key={1} minWidth={240}>
            <LocationSearch
              maxZoom={19}
              onSearch={searchLocation(
                mapboxAccessToken,
                center && [center.lng, center.lat]
              )}
            />
          </Box>
        </MapOverlay>
      ]}
      mapOverlayTopRight={[
        <MapOverlay key="navigation">
          <Box>
            <ZoomInButton />
            <Divider />
            <ZoomOutButton />
          </Box>
        </MapOverlay>,
        <MapOverlay key="geolocation">
          <GeolocationButton />
        </MapOverlay>
      ]}
      sidebar={
        sidebar && (
          <Sidebar
            height={1}
            title={sidebars[sidebar].title}
            onClose={() => setSidebar(undefined)}
            content={sidebars[sidebar].content}
            enableSearch={sidebars[sidebar].enableSearch}
            onSearch={searchSections}
            onClearSearch={() => searchSections('')}
          />
        )
      }
    />
  );
};

export default Layout;
